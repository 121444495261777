import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2022 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2022';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2022: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2022} {...props}>
    <ReportUnicef2022 />
  </FundUnicefTemplate>
);

export default FundUnicef2022;